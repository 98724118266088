import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseRevisionFragmentDoc } from '../fragments/baseRevision';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRevisionBasicQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetRevisionBasicQuery = { __typename?: 'Query', revision: { __typename?: 'Revision', id: string, name?: string | null | undefined, index: number, isPrimary: boolean, createdAt: number, isProcessed: boolean, status: Types.RevisionStatus, isLocked: boolean, isMine: boolean, canPlaythrough: boolean, canDownload: Types.CanDownload, canDelete: boolean, canLock: boolean, canUnlock: boolean, canSetPrimary: boolean, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined, wavFilename?: string | null | undefined } | null | undefined, settings?: { __typename?: 'RevisionSettings', colors: Types.RevisionColors, dynamics: Types.RevisionDynamics } | null | undefined, track: { __typename?: 'Track', id: string, index: number, title: string, hasPendingRevisions: boolean, canEdit: boolean, canDelete: boolean, updatedAt: number, status?: Types.RevisionStatus | null | undefined, primaryRevision?: { __typename?: 'Revision', id: string, canDownload: Types.CanDownload, isProcessed: boolean, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined } | null | undefined, album?: { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, canUpload: boolean } | null | undefined } } };


export const GetRevisionBasicDocument = gql`
    query GetRevisionBasic($id: UUID!) {
  revision(id: $id) {
    ...BaseRevision
  }
}
    ${BaseRevisionFragmentDoc}`;

/**
 * __useGetRevisionBasicQuery__
 *
 * To run a query within a React component, call `useGetRevisionBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevisionBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevisionBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRevisionBasicQuery(baseOptions: Apollo.QueryHookOptions<GetRevisionBasicQuery, GetRevisionBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRevisionBasicQuery, GetRevisionBasicQueryVariables>(GetRevisionBasicDocument, options);
      }
export function useGetRevisionBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRevisionBasicQuery, GetRevisionBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRevisionBasicQuery, GetRevisionBasicQueryVariables>(GetRevisionBasicDocument, options);
        }
export type GetRevisionBasicQueryHookResult = ReturnType<typeof useGetRevisionBasicQuery>;
export type GetRevisionBasicLazyQueryHookResult = ReturnType<typeof useGetRevisionBasicLazyQuery>;
export type GetRevisionBasicQueryResult = Apollo.QueryResult<GetRevisionBasicQuery, GetRevisionBasicQueryVariables>;