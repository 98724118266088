import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseRevisionFragmentDoc } from '../fragments/baseRevision';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRevisionMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
  track: Types.Scalars['UUID'];
  original: Types.Scalars['URL'];
  bypass?: Types.InputMaybe<Types.Scalars['Boolean']>;
  useV2Engine?: Types.InputMaybe<Types.Scalars['Boolean']>;
  settings?: Types.InputMaybe<Types.RevisionSettingsInput>;
}>;


export type CreateRevisionMutation = { __typename?: 'Mutation', createRevision: { __typename?: 'RevisionMutationResponse', revision: { __typename?: 'Revision', id: string, name?: string | null | undefined, index: number, isPrimary: boolean, createdAt: number, isProcessed: boolean, status: Types.RevisionStatus, isLocked: boolean, isMine: boolean, canPlaythrough: boolean, canDownload: Types.CanDownload, canDelete: boolean, canLock: boolean, canUnlock: boolean, canSetPrimary: boolean, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined, wavFilename?: string | null | undefined } | null | undefined, settings?: { __typename?: 'RevisionSettings', colors: Types.RevisionColors, dynamics: Types.RevisionDynamics } | null | undefined, track: { __typename?: 'Track', id: string, index: number, title: string, hasPendingRevisions: boolean, canEdit: boolean, canDelete: boolean, updatedAt: number, status?: Types.RevisionStatus | null | undefined, primaryRevision?: { __typename?: 'Revision', id: string, canDownload: Types.CanDownload, isProcessed: boolean, original?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined, master?: { __typename?: 'AudioData', mp3?: string | null | undefined, wav?: string | null | undefined } | null | undefined } | null | undefined, album?: { __typename?: 'Album', id: string, title: string, artist?: string | null | undefined, artwork?: string | null | undefined, canUpload: boolean } | null | undefined } } } };


export const CreateRevisionDocument = gql`
    mutation CreateRevision($name: String, $track: UUID!, $original: URL!, $bypass: Boolean, $useV2Engine: Boolean, $settings: RevisionSettingsInput) {
  createRevision(
    input: {name: $name, track: $track, original: $original, settings: $settings, bypass: $bypass, useV2Engine: $useV2Engine}
  ) {
    revision {
      ...BaseRevision
    }
  }
}
    ${BaseRevisionFragmentDoc}`;
export type CreateRevisionMutationFn = Apollo.MutationFunction<CreateRevisionMutation, CreateRevisionMutationVariables>;

/**
 * __useCreateRevisionMutation__
 *
 * To run a mutation, you first call `useCreateRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevisionMutation, { data, loading, error }] = useCreateRevisionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      track: // value for 'track'
 *      original: // value for 'original'
 *      bypass: // value for 'bypass'
 *      useV2Engine: // value for 'useV2Engine'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useCreateRevisionMutation(baseOptions?: Apollo.MutationHookOptions<CreateRevisionMutation, CreateRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRevisionMutation, CreateRevisionMutationVariables>(CreateRevisionDocument, options);
      }
export type CreateRevisionMutationHookResult = ReturnType<typeof useCreateRevisionMutation>;
export type CreateRevisionMutationResult = Apollo.MutationResult<CreateRevisionMutation>;
export type CreateRevisionMutationOptions = Apollo.BaseMutationOptions<CreateRevisionMutation, CreateRevisionMutationVariables>;