import create from 'zustand';

export interface Breadcrumb {
  path?: string;
  text: string;
}

export interface BreadcrumbsState {
  breadcrumbs: Breadcrumb[] | null;

  clearBreadcrumbs: () => void;
  addBreadcrumb: (breadcrumb: Breadcrumb) => void;
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
}

export const useBreadcrumbsStore = create<BreadcrumbsState>((set, get) => ({
  breadcrumbs: null,

  clearBreadcrumbs: () => set({ breadcrumbs: null }),
  addBreadcrumb: (breadcrumb: Breadcrumb) => set({ breadcrumbs: [...(get()?.breadcrumbs || []), breadcrumb] }),
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => set({ breadcrumbs }),
})) 