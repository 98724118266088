import React, { FC, useMemo } from 'react';
import { Avatar } from '@mui/material';
import { Image } from 'common/components/Image';
import { useGetUserQuery } from 'user/queries/getUser';

interface UserAvatarProps {
  id: string;
  className?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({ id, className }) => {
  const { data } = useGetUserQuery({ variables: { id } });
  const initials = useMemo(() => {
    return `${data?.user?.firstName?.[0]}${data?.user?.lastName?.[0]}`;
  }, [data]);

  return (
    <Avatar className={className}>
      {data?.user?.avatar ? (
        <Image
          objectFit={'cover'}
          priority
          src={data?.user?.avatar}
          width={40}
          height={40}
        />
      ) : (
        initials ?? initials
      )}
    </Avatar>
  );
};
