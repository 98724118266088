import { Avatar, Box, ListItemAvatar, ListItemText } from '@mui/material';
import { FC, useEffect } from 'react';
import { useGetUserLazyQuery } from 'user/queries/getUser';
import { UserAvatar } from './UserAvatar';

export interface UserLockupProps {
  id?: string;
  email?: string | null;
}

export const UserLockup: FC<UserLockupProps> = ({ id, email }) => {
  const [getUserQuery, { data }] = useGetUserLazyQuery();
  const user = data?.user;

  useEffect(() => {
    if (!id) {
      return;
    }
    getUserQuery({ variables: { id } });
  }, [id]);

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <ListItemAvatar>
        {user ? (
          <UserAvatar key={user.id} id={user.id} />
        ) : (
          <Avatar key={email}>?</Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={user ? `${user.firstName} ${user.lastName}` : email}
        secondary={user?.username ? `@${user?.username}` : undefined}
      />
    </Box>
  );
};
