import * as Types from '../../common/types/graphql-types';

import { gql } from '@apollo/client';
import { BaseUserFragmentDoc } from '../fragments/baseUser';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeBillingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeBillingQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, avatar?: string | null | undefined, firstName: string, lastName: string, username: string, defaultCard?: { __typename?: 'Card', brand: Types.CardBrand, last4: string, expiration: string } | null | undefined, cards: Array<{ __typename?: 'Card', id: string, brand: Types.CardBrand, last4: string, isDefault: boolean, expiration: string }>, orders?: Array<{ __typename?: 'Order', id: string, amount: number, paid: boolean, status?: string | null | undefined, createdAt: number }> | null | undefined, subscription?: { __typename?: 'UserSubscription', cancelAtPeriodEnd: boolean, product: { __typename?: 'Product', id: string, name: string, features: Array<string>, prices: Array<{ __typename?: 'Price', id: string, interval?: Types.PriceInterval | null | undefined, amount: number }> } } | null | undefined } };


export const MeBillingDocument = gql`
    query MeBilling {
  me {
    ...BaseUser
    defaultCard {
      brand
      last4
      expiration
    }
    cards {
      id
      brand
      last4
      isDefault
      expiration
    }
    orders {
      id
      amount
      paid
      status
      createdAt
    }
    subscription {
      cancelAtPeriodEnd
      product {
        id
        name
        features
        prices {
          id
          interval
          amount
        }
      }
    }
  }
}
    ${BaseUserFragmentDoc}`;

/**
 * __useMeBillingQuery__
 *
 * To run a query within a React component, call `useMeBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeBillingQuery(baseOptions?: Apollo.QueryHookOptions<MeBillingQuery, MeBillingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeBillingQuery, MeBillingQueryVariables>(MeBillingDocument, options);
      }
export function useMeBillingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeBillingQuery, MeBillingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeBillingQuery, MeBillingQueryVariables>(MeBillingDocument, options);
        }
export type MeBillingQueryHookResult = ReturnType<typeof useMeBillingQuery>;
export type MeBillingLazyQueryHookResult = ReturnType<typeof useMeBillingLazyQuery>;
export type MeBillingQueryResult = Apollo.QueryResult<MeBillingQuery, MeBillingQueryVariables>;