export const REVISION_UPLOADED = 'Revision Uploaded';
export const REVISION_DOWNLOADED = 'Revision Downloaded';
export const REVISION_SELECTED = 'Revision Selected';
export const REVISION_MADE_PRIMARY = 'Revision Made Primary';
export const REVISION_DELETED = 'Revision Deleted';
export const TOGGLE_REVISION_SOURCE = 'Toggle Revision Source';

export const TOGGLE_REVISION_COMMENTS_LOCK = 'Toggle Revision Comments Lock';
export const REVISION_COMMENT_CREATED = 'Revision Comment Created';
export const REVISION_COMMENT_VOTE = 'Revision Comment Vote';
export const REVISION_COMMENT_LINK_CLICK = 'Revision Comment Link Click';
export const REVISION_COMMENT_USER_MENTION = 'Revision Comment User Mention';
export const REVISION_COMMENT_REVISION_MENTION =
  'Revision Comment Revision Mention';
export const REVISION_COMMENT_TIMESTAMP_MENTION =
  'Revision Comment Timestamp Mention';

export const TOGGLE_REVISION_PLAYING = 'Toggle Revision Playing';
export const REVISION_PLAYER_PREV = 'Revision Player Prev';
export const REVISION_PLAYER_NEXT = 'Revision Player Next';

export const LOAD_MORE_TRACKS = 'Load More Tracks';
export const TRACK_CREATED = 'Track Created';
export const TRACK_UPDATED = 'Track Updated';
export const TRACK_DELETED = 'Track Deleted';
